import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { srConfig } from '@config'; //email
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledContactSection = styled.section`
  max-width: 600px;
  margin: 0 auto 100px;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 auto 50px;
  }

  .overline {
    display: block;
    margin-bottom: 20px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: var(--fz-md);
    font-weight: 400;

    &:before {
      bottom: 0;
      font-size: var(--fz-sm);
    }

    &:after {
      display: none;
    }
  }

  .title {
    font-size: clamp(40px, 5vw, 60px);
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
  }
`;

const Contact = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  return (
    <StyledContactSection id="contact" ref={revealContainer}>
      <h2 className="numbered-heading overline">What’s Next?</h2>

      <h2 className="title">Get In Touch</h2>

      <p>
        I’m seeking a position as a React Native developer with a strong focus on building dynamic
        and efficient mobile applications. Proficient in JavaScript, TypeScript, HTML, CSS, and
        Redux Toolkit, I thrive in fast-paced environments where I can tackle challenges, learn new
        technologies, and deliver impactful solutions. My passion for creating seamless user
        experiences drives my dedication to this field.
      </p>

      <a
        className="email-link"
        href={
          'https://www.upwork.com/services/consultation/development-it-muhammad-usama-1491695804163969024'
        }
      >
        Say Hello
      </a>
    </StyledContactSection>
  );
};
// href={`mailto:${email}`
export default Contact;
